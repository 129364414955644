import LargeTextHandler from '../../../../common-utilities/core/large-text';
import {
  convertToCamelCaseFromUnderScore,
  formatDate,
} from '../../../../common-utilities/utils';
import { DATE_FORMATS } from '../../../../common-utilities/utils/constants';

export const getHeaderConfig = () => {
  const config = [
    {
      label: 'Old Price',
      id: 'oldPrice',
    },
    {
      label: 'Update Price',
      id: 'updatedPrice',
    },
    {
      label: 'User Name',
      id: 'creatorName',
    },
    {
      label: 'Date',
      id: 'createdAt',
      valueConversionLogic: (val) =>
        formatDate(val, DATE_FORMATS['DD-MM-YYYY']),
    },
    {
      label: 'Status',
      id: 'status',
      valueConversionLogic: (val) => convertToCamelCaseFromUnderScore(val),
    },

    {
      label: 'Approver Comments',
      id: 'ApproverComments',
      render: (data) => {
        const stages = data?.approvalId?.stages || [];
        return (
          <div>
            {stages.map((stage, index) => {
              return (
                <div key={index} className="line-item-history-comments">
                  <div>
                    <b>Approver {index + 1}</b> -{' '}
         
                    <LargeTextHandler
                      maxWords={20}
                      text={
                        stage.approvalComments || stage.rejectionReason || ''
                      }
                    />
             
                  </div>
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];
  return config;
};
