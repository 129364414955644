import React, { useState, useEffect } from "react";
import "./index.scss";
import {
  budgetStatus,
  getGrnHeadersConfig,
  getPoCapexHeadersConfig,
  grnFilters,
} from "./helper";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import {
  FINANCE_HOSTNAME,
  INTRANETHOSTNAME,
  INVOICE_TRACKING_HOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { FINANCE_REST_URLS } from "../../../common-utilities/utils/end-points/finance";
import { toast } from "react-toastify";
import MuiTable from "../../../common-utilities/core/mui-table";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import {
  AutoCompleteSelect,
  DateRange,
  NumberRange,
  getLast15DaysDateRange,
} from "./utils/utils";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import ClearIcon from "@mui/icons-material/Clear";
import { exportToExcel } from "../../../common-utilities/utils";
import CustomModal from "../../../common-utilities/core/modal";
import { PO_TYPES } from "../purchase-order/helper";
import { setProperty } from "../../../common-utilities/utils/misc";
import {
  getStartOfDay,
  getEndOfDay,
} from "../../../common-utilities/utils/time";
const GrnReport = () => {
  const [grnList, setGrnList] = useState({});
  const [filterLabel, setFilterLabel] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sortBy: "-createdAt",
  });
  const [categoryList, setCategoryList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [vendorPaymentTermList, setVendorPaymentTermList] = useState([]);
  const [poNumberList, setPoNumberList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [showLargeText, setShowLargeText] = useState({
    isOpen: false,
    text: "",
  });
  const [config, setConfig] = useState({
    showTable: true,
    approverHeaders: [],
  });

  const addSearchParams = (params) => {
    switch (filterLabel) {
      case "creationDate":
        if (
          ![null, undefined, NaN, ""].includes(filterValue?.startDate) &&
          ![null, undefined, NaN, ""].includes(filterValue?.endDate)
        ) {
          params["startDate"] = getStartOfDay(filterValue?.startDate, true);
          params["endDate"] = getEndOfDay(filterValue?.endDate, true);
          params["dateKey"] = filterLabel;
        }

        break;
      case "paymentTerm":
        if (![null, undefined, NaN, ""].includes(filterValue)) {
          params["keyName"] = "vendorCode";
          params["keyValue"] = filterValue;
        }

        break;
      default:
        if (![null, undefined, NaN, ""].includes(filterValue)) {
          params["keyName"] = filterLabel;
          params["keyValue"] = filterValue;
        }

        break;
    }
  };
  const loadData = (filters, isDownload = false) => {
    const { startDate, endDate } = getLast15DaysDateRange();

    let params = {
      ...filters,
    };
    if (!filters?.startDate) {
      params["startDate"] = startDate;
    }
    if (!filters?.endDate) {
      params["endDate"] = endDate;
    }
    if (!isDownload) {
      setProperty("showTable", false, setConfig);
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.REPORTS}grn`,
      null,
      params
    )
      .then((response) => {
        if (response.message) {
          setProperty("showTable", true, setConfig);
          toast.error(response.message, { autoClose: 2000 });
        }
        if (isDownload) {
          exportToExcel(response?.results?.grnData || [], "Invoice Report");
        } else {
          const approvalHeaders =
            response?.results?.stageLabelList?.map?.((item) => {
              return {
                label: item,
                id: item,
              };
            }) || [];

          setProperty("approverHeaders", approvalHeaders, setConfig);

          if (response?.results?.grnData) {
            response.results = [...response?.results?.grnData];
          } else {
            response.results = [];
          }
          setGrnList(response);
          setTimeout(() => {
            setProperty("showTable", true, setConfig);
          }, 300);
        }
      })
      .catch((err) => {
        setProperty("showTable", true, setConfig);
        console.log(err);
      });
  };
  const searchHandler = () => {
    let params = { ...filters, page: 1 };
    addSearchParams(params);
    loadData(params);
  };
  const getCategoryList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_CATEGORY_LIST}`
    )
      .then((res) => {
        const result = res.map((ele) => ({ label: ele.name, value: ele.id }));
        setCategoryList(result);
      })
      .catch((err) => console.log(err));
  };
  const getPoNumberList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_PO_NUMBER_LIST}`,
      null,
      { selection: "poNumber" }
    )
      .then((res) => {
        const result = res.map((ele) => ({
          label: ele?.poNumber,
          value: ele?.poNumber,
        }));
        setPoNumberList(result);
      })
      .catch((err) => console.log(err));
  };
  const getVendorList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INVOICE_TRACKING_HOSTNAME}${FINANCE_REST_URLS.VENDOR_LIST}`,
      null,
      { additionalFields: "paymentTerms" }
    )
      .then((res) => {
        const result = res.map((ele) => ({
          label: `${ele?.code}- ${ele?.name}- ${ele?.gst}`,
          value: ele.code,
        }));
        const paymentsTermResult = res.map((ele) => ({
          label: `${ele?.name}- ${ele?.paymentTerms || ""}`,
          value: ele.code,
        }));
        setVendorList(result);
        setVendorPaymentTermList(paymentsTermResult);
      })
      .catch((err) => console.log(err));
  };
  const getStoreList = (filter) => {
    let params = {};
    if (filter === "site") {
      params = {
        additionalFields: "gstNumber,storeId",
        poBudgetLocations: true,
        getStoreData: true,
      };
    } else {
      params = {
        additionalFields: "gstNumber,storeId",
      };
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.STORE_DATA_LIST}`,
      null,
      params
    )
      .then((res) => {
        if (filter === "site") {
          const result = res.map((ele) => ({
            label: `${ele?.code}- ${ele?.gstNumber}`,
            value: ele?._id,
          }));
          setStoreList(result);
        } else {
          const locationResult = res.map((ele) => ({
            label: ele.name,
            value: ele.id,
          }));
          setLocationList(locationResult);
        }
      })
      .catch((err) => console.log(err));
  };
  const getSubCategoryList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_SUB_CATEGORY_LIST}`
    )
      .then((res) => {
        const result = res.map((ele) => ({
          label: ele.name,
          value: ele?._id,
        }));
        setSubCategoryList(result);
      })
      .catch((err) => console.log(err));
  };
  const getDepartmentList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${FINANCE_REST_URLS.GET_HOD_DEPARTMENT_LIST}`,
      null,
      {
        page: 1,
        limit: 1000,
      }
    )
      .then((res) => {
        const result = res.results.map((ele) => ({
          label: `${ele.name}- ${ele?.head?.name || ""}`,
          value: ele?.id,
        }));
        setDepartmentList(result);
      })
      .catch((err) => console.log(err));
  };

  const getItemList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_ITEM_LIST}`
    )
      .then((res) => {
        const result = res.map((ele) => ({
          label: ele.name,
          value: ele?._id,
        }));
        setItemList(result);
      })
      .catch((err) => console.log(err));
  };
  const getCompanyList = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${FINANCE_HOSTNAME}${FINANCE_REST_URLS.GET_COMPANY_LIST}`
    )
      .then((res) => {
        const result = res.map((ele) => ({
          label: ele?.name,
          value: ele?.id,
        }));
        setCompanyList(result);
      })
      .catch((err) => console.log(err));
  };
  const getFilterValue = (type) => {
    switch (type) {
      case "site":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={storeList}
            filterLabel={filterLabel}
          />
        );
      case "vendorCode":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={vendorList}
            filterLabel={filterLabel}
          />
        );
      case "paymentTerm":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={vendorPaymentTermList}
            filterLabel={filterLabel}
          />
        );
      case "poType":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={PO_TYPES}
            filterLabel={filterLabel}
          />
        );
      case "poNumber":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={poNumberList}
            filterLabel={filterLabel}
          />
        );
      case "category":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={categoryList}
            filterLabel={filterLabel}
          />
        );
      case "subCategory":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={subCategoryList}
            filterLabel={filterLabel}
          />
        );
      case "itemId":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={itemList}
            filterLabel={filterLabel}
          />
        );
      case "department":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={departmentList}
            filterLabel={filterLabel}
          />
        );
      case "deliveryLocationId":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={locationList}
            filterLabel={filterLabel}
          />
        );
      case "billingLocationId":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={locationList}
            filterLabel={filterLabel}
          />
        );
      case "company":
        return (
          <AutoCompleteSelect
            setFilterValue={setFilterValue}
            optionsList={companyList}
            filterLabel={filterLabel}
          />
        );
      case "creationDate":
        return <DateRange setFilterValue={setFilterValue} />;

      default:
        break;
    }
  };
  const downloadReport = () => {
    const params = { ...filters, download: true };
    params.page = 1;
    params.limit = grnList.totalResults;
    addSearchParams(params);
    loadData(params, true);
  };
  useEffect(() => {
    loadData(filters);
  }, []);
  useEffect(() => {
    if (filterLabel === "category") {
      getCategoryList();
    }
    if (
      filterLabel === "site" ||
      filterLabel == "deliveryLocationId" ||
      filterLabel === "billingLocationId"
    ) {
      getStoreList(filterLabel);
    }
    if (filterLabel === "vendorCode" || filterLabel === "paymentTerm") {
      getVendorList();
    }
    if (filterLabel === "poNumber") {
      getPoNumberList();
    }
    if (filterLabel === "subCategory") {
      getSubCategoryList();
    }
    if (filterLabel === "itemId") {
      getItemList();
    }
    if (filterLabel === "department") {
      getDepartmentList();
    }
    if (filterLabel === "company") {
      getCompanyList();
    }
  }, [filterLabel]);

  return (
    <div>
      <div className="reports-filter-con">
        <Grid
          container
          md={12}
          sx={{ mt: 2, display: "flex", alignItems: "center" }}
        >
          <Grid item md={2} sx={{ mr: 2 }}>
            <Autocomplete
              options={grnFilters}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Select Filter" />
              )}
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    setFilterLabel("");
                    setFilterValue("");
                    loadData({ ...filters, page: 1 });
                  }}
                />
              }
              onChange={(e, val) => {
                if (val) {
                  setFilterValue("");
                  setFilterLabel(val.value);
                }
              }}
            />
          </Grid>

          {filterLabel && getFilterValue(filterLabel)}

          <Grid md={2} sx={{ ml: 2 }}>
            {filterLabel && (
              <Button
                variant="outlined"
                onClick={searchHandler}
                disabled={
                  !filterValue ||
                  (typeof filterValue === "object" &&
                    Object.keys(filterValue).length === 0)
                }
              >
                Search
              </Button>
            )}
          </Grid>
        </Grid>
        <DownloadBtn
          onClick={() => {
            downloadReport();
          }}
        />
      </div>
      {config.showTable && (
        <MuiTable
          columnsList={getGrnHeadersConfig(config.approverHeaders)}
          dataList={grnList.results || []}
          filters={filters}
          maxWords={3}
          pageCount={grnList.totalPages}
          showAllFunc={setShowLargeText}
          onChange={(page) => {
            setFilters({
              ...filters,
              page,
            });
            const params = {
              ...filters,
              page,
            };
            addSearchParams(params);
            loadData(params);
          }}
        />
      )}

      {showLargeText.isOpen && (
        <CustomModal
          onClose={() =>
            setShowLargeText({
              isOpen: false,
              text: "",
            })
          }
        >
          {showLargeText.text}
        </CustomModal>
      )}
    </div>
  );
};

export default GrnReport;
