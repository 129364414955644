import CustomModal from "../../../common-utilities/core/modal";
import { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import {
  DMB_TYPE,
  REGIONS_LIST,
  SCREEN_FORMAT,
  STORE_STATUS_LIST,
  STORE_TYPE,
  STORE_TYPE_MAP,
  UserRoles,
} from "./config";

import {
  createUpdateStore,
  disableSaveButton,
  getSelectDcList,
  setRegionsData,
  userListSelectList,
} from "./utils";
import { clearRegionKeys } from "../../../common-utilities/utils/region-utils";
import AutocompleteComponent from "../../../common-utilities/core/styled-autocomplete";
import { formatDate } from "../../../../utils";
import { DATE_FORMATS } from "../../../../utils/constants";

export const CreateUpdateStoreModal = ({
  data,
  showForm,
  setShowForm,
  onSuccess,
  url,
  tokenKey,
  createStoreData,
}) => {
  const [storeData, setStoreData] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [amList, setAMList] = useState([]);
  const [rmList, setRmList] = useState([]);
  const [dcList, setDcList] = useState([]);
  const [userList, setUserList] = useState([]);
  useEffect(() => {
    if (!showForm) {
      return;
    }
    if (data || createStoreData) {
      const finalData = { ...data, ...createStoreData };
      if (finalData.storeOpeningDate) {
        finalData.storeOpeningDate = formatDate(
          finalData.storeOpeningDate,
          DATE_FORMATS["YYYY-MM-DD"]
        );
      }
      if (finalData.storeTransferDate) {
        finalData.storeTransferDate = formatDate(
          finalData.storeTransferDate,
          DATE_FORMATS["YYYY-MM-DD"]
        );
      }
      setStoreData(finalData);
    }

    setRegionsData(
      data || createStoreData,
      countryList,
      setCountryList,
      setStateList,
      setCityList
    );
    (async () => {
      const dc = await getSelectDcList(null, null, tokenKey);
      setDcList(dc);
      const areaManger = await userListSelectList(
        {
          additionalRole: UserRoles.areaManger,
        },
        tokenKey
      );
      setAMList(areaManger);
      const regionalManger = await userListSelectList(
        {
          additionalRole: UserRoles.regionalManger,
        },
        tokenKey
      );
      setRmList(regionalManger);
      const allUsers = await userListSelectList({}, tokenKey);
      setUserList(allUsers);
    })();
  }, [data, showForm, tokenKey, createStoreData]);

  const clearData = () => {
    setStoreData({});
    setCountryList([]);
    setStateList([]);
    setCityList([]);
  };

  useEffect(() => {
    if (!showForm) {
      clearData();
    }
  }, [showForm]);
  const onInputChange = (e, type) => {
    switch (type) {
      case "select": {
        const { name, value } = e;
        setStoreData((prevDetails) => {
          clearRegionKeys(
            prevDetails,
            name,
            value,
            "country",
            "state",
            "city",
            setStateList,
            setCityList
          );
          return {
            ...prevDetails,
            [name]: value,
          };
        });
        return;
      }
      default: {
        const { name, value } = e.target;
        setStoreData((prevDetails) => ({
          ...prevDetails,
          [name]: value,
        }));
      }
    }
  };
  return (
    <div className="store-create-edit-modal">
      {showForm && (
        <CustomModal
          classesToPreventClosing={["MuiAutocomplete-option"]}
          contentClassName={{
            customClass: "store-create-edit-modal-container",
          }}
          title={`${data ? "Update store details" : "Create new store"}`}
          onClose={() => setShowForm(null)}
        >
          <div className="input-field">
            <div className="mandatory-fields">
              Name <span>*</span>
            </div>

            <TextField
              size="small"
              value={storeData.name || ""}
              name="name"
              type="text"
              placeholder="Name"
              className="new-textfield"
              onChange={onInputChange}
            />
          </div>

          <div className="input-field">
            <div className="mandatory-fields">
              Store Number <span>*</span>
            </div>
            <TextField
              size="small"
              value={storeData.storeNumber || ""}
              name="storeNumber"
              type="number"
              placeholder="Store Number"
              className="new-textfield"
              onChange={onInputChange}
            />
          </div>
          <div className="input-field">
            <div className="mandatory-fields">
              Satellite Number <span>*</span>
            </div>
            <TextField
              size="small"
              value={storeData.satelliteNumber || ""}
              name="satelliteNumber"
              type="number"
              className="new-textfield"
              placeholder="Satellite Number"
              onChange={onInputChange}
            />
          </div>

          <div className="input-field">
            <div className="mandatory-fields">
              Sequence Number <span>*</span>
            </div>

            <TextField
              size="small"
              value={storeData.sequenceNumber || ""}
              name="sequenceNumber"
              className="new-textfield"
              type="text"
              placeholder="Sequence Number"
              onChange={onInputChange}
            />
          </div>

          <div className="input-field">
            <div className="mandatory-fields">
              Store Name<span>*</span>
            </div>

            <TextField
              size="small"
              value={storeData.ownerName || ""}
              name="ownerName"
              className="new-textfield"
              type="text"
              placeholder="Store Name"
              onChange={onInputChange}
            />
          </div>

          <div className="input-field">
            <div className="mandatory-fields">
              Store Type<span>*</span>
            </div>
            <AutocompleteComponent
              classNamePrefix="custom_select"
              label="Store Type"
              className="sel-item"
              options={STORE_TYPE}
              value={
                ![undefined, null, ""].includes(storeData.storeType)
                  ? STORE_TYPE.find((i) => i.value === storeData.storeType)
                  : ""
              }
              disableClear={true}
              onChange={(e, val) => {
                onInputChange(
                  { name: "storeType", value: val?.value },
                  "select"
                );
              }}
            />
          </div>

          <div className="input-field">
            Store Display Name
            <TextField
              size="small"
              value={storeData.restaurantName || ""}
              name="restaurantName"
              type="text"
              className="new-textfield"
              placeholder="Store Display Name"
              onChange={onInputChange}
            />
          </div>

          <div className="input-field">
            <div className="mandatory-fields">
              Store Opening Date
              {(storeData?.status === "Active" ||
                storeData?.status === "Inactive") && <span>*</span>}
            </div>

            <TextField
              size="small"
              value={storeData.storeOpeningDate || ""}
              name="storeOpeningDate"
              type="date"
              placeholder="Store Opening Date"
              className="new-textfield"
              onChange={onInputChange}
            />
          </div>
          <div className="input-field">
            Store Transfer Date
            <TextField
              size="small"
              value={storeData.storeTransferDate || ""}
              name="storeTransferDate"
              type="date"
              placeholder="Store Transfer Date"
              className="new-textfield"
              onChange={onInputChange}
            />
          </div>

          <div className="input-field">
            <div className="mandatory-fields">
              Format
              <span>*</span>
            </div>

            <TextField
              size="small"
              value={storeData.format || ""}
              name="format"
              type="text"
              className="new-textfield"
              placeholder="Format"
              onChange={onInputChange}
            />
          </div>

          <div className="input-field">
            <div className="mandatory-fields">
              Sub Format
              <span>*</span>
            </div>
            <TextField
              size="small"
              value={storeData.subFormat || ""}
              name="subFormat"
              type="text"
              className="new-textfield"
              placeholder="Sub Format"
              onChange={onInputChange}
            />
          </div>

          <div className="input-field">
            <div className="mandatory-fields">
              Status
              <span>*</span>
            </div>
            <AutocompleteComponent
              classNamePrefix="custom_select"
              label="Store Status"
              className="sel-item"
              options={STORE_STATUS_LIST}
              value={
                ![undefined, null, ""].includes(storeData.status)
                  ? STORE_STATUS_LIST.find((i) => i.value === storeData.status)
                  : ""
              }
              onChange={(e, val) => {
                onInputChange({ name: "status", value: val?.value }, "select");
              }}
              disableClear={true}
            />
          </div>

          <div className="input-field">
            <div className="mandatory-fields">
              Address
              <span>*</span>
            </div>
            <TextField
              size="small"
              value={storeData.address || ""}
              name="address"
              type="text"
              placeholder="Address"
              className="new-textfield"
              onChange={onInputChange}
            />
          </div>

          <div className="input-field">
            <div className="mandatory-fields">
              Country
              <span>*</span>
            </div>
            <AutocompleteComponent
              classNamePrefix="custom_select"
              label="Country"
              className="sel-item"
              options={countryList}
              value={
                ![undefined, null, ""].includes(storeData.country)
                  ? countryList.find((i) => i.value === storeData.country)
                  : ""
              }
              onChange={(e, val) => {
                onInputChange({ name: "country", value: val?.value }, "select");
              }}
              disableClear={true}
            />
          </div>

          <div className="input-field">
            <div className="mandatory-fields">
              State
              <span>*</span>
            </div>
            <AutocompleteComponent
              classNamePrefix="custom_select"
              label="State"
              className="sel-item"
              options={stateList}
              value={
                ![undefined, null, ""].includes(storeData.state)
                  ? stateList.find((i) => i.value === storeData.state)
                  : ""
              }
              onChange={(e, val) => {
                onInputChange({ name: "state", value: val?.value }, "select");
              }}
              disableClear={true}
            />
          </div>

          <div className="input-field">
            City
            <AutocompleteComponent
              classNamePrefix="custom_select"
              label="City"
              className="sel-item"
              options={cityList}
              value={
                ![undefined, null, ""].includes(storeData.city)
                  ? cityList.find((i) => i.value === storeData.city)
                  : ""
              }
              onChange={(e, val) => {
                onInputChange({ name: "city", value: val?.value }, "select");
              }}
              disableClear={true}
            />
          </div>

          <div className="input-field">
            <div className="mandatory-fields">
              Region
              <span>*</span>
            </div>
            <AutocompleteComponent
              classNamePrefix="custom_select"
              label="Region"
              className="sel-item"
              options={REGIONS_LIST}
              value={
                ![undefined, null, ""].includes(storeData.region)
                  ? REGIONS_LIST.find((i) => i.value === storeData.region)
                  : ""
              }
              onChange={(e, val) => {
                onInputChange({ name: "region", value: val?.value }, "select");
              }}
              disableClear={true}
            />
          </div>
          <div className="input-field">
            Area
            <TextField
              size="small"
              value={storeData.area || ""}
              name="area"
              type="text"
              className="new-textfield"
              placeholder="Area"
              onChange={onInputChange}
            />
          </div>
          <div className="input-field">
            Pincode
            <TextField
              size="small"
              value={storeData.pinCode || ""}
              name="pinCode"
              type="number"
              placeholder="Pincode"
              className="new-textfield"
              onChange={onInputChange}
            />
          </div>

          <div className="input-field">
            Latitude
            <TextField
              size="small"
              value={storeData.latitude || ""}
              name="latitude"
              type="number"
              placeholder="Latitude"
              className="new-textfield"
              onChange={onInputChange}
            />
          </div>

          <div className="input-field">
            Longtitude
            <TextField
              size="small"
              value={storeData.longitude || ""}
              name="longitude"
              type="number"
              placeholder="Longtitude"
              className="new-textfield"
              onChange={onInputChange}
            />
          </div>
          <div className="input-field">
            Circle
            <TextField
              size="small"
              value={storeData.circle || ""}
              name="circle"
              placeholder="Circle"
              className="new-textfield"
              onChange={onInputChange}
            />
          </div>
          <div className="input-field">
            <div className="mandatory-fields">
              Area Manager{" "}
              {storeData?.storeType !== STORE_TYPE_MAP.FOFO && <span>*</span>}
            </div>
            <AutocompleteComponent
              classNamePrefix="custom_select"
              label="Area Manager"
              disableClear={true}
              className="sel-item"
              options={amList}
              value={
                ![undefined, null, ""].includes(storeData.am)
                  ? amList.find((i) => i.value === storeData.am)
                  : ""
              }
              onChange={(e, val) => {
                onInputChange({ name: "am", value: val?.value }, "select");
              }}
            />
          </div>

          <div className="input-field">
            <div className="mandatory-fields">
              Regional Manager{" "}
              {storeData?.storeType !== STORE_TYPE_MAP.FOFO && <span>*</span>}
            </div>
            <AutocompleteComponent
              classNamePrefix="custom_select"
              placeholder="Regional Manager"
              className="sel-item"
              options={rmList}
              value={
                ![undefined, null, ""].includes(storeData.rm)
                  ? rmList.find((i) => i.value === storeData.rm)
                  : ""
              }
              onChange={(e, val) => {
                onInputChange({ name: "rm", value: val?.value }, "select");
              }}
              disableClear={true}
            />
          </div>

          <div className="input-field">
            <div className="mandatory-fields">
              DC<span>*</span>
            </div>
            <AutocompleteComponent
              classNamePrefix="custom_select"
              label="DC"
              className="sel-item"
              options={dcList}
              value={
                ![undefined, null, ""].includes(storeData.dc)
                  ? dcList.find((i) => i.value === storeData.dc)
                  : ""
              }
              onChange={(e, val) => {
                onInputChange({ name: "dc", value: val?.value }, "select");
              }}
              disableClear={true}
            />
          </div>

          <div className="input-field">
            <div className="mandatory-fields">
              Store Email<span>*</span>
            </div>
            <TextField
              size="small"
              value={storeData.email || ""}
              name="email"
              type="text"
              placeholder="Store Email"
              className="new-textfield"
              onChange={onInputChange}
            />
          </div>

          <div className="input-field">
            FZ Name
            <TextField
              size="small"
              value={storeData.fzName || ""}
              name="fzName"
              type="text"
              className="new-textfield"
              placeholder="FZ Name"
              onChange={onInputChange}
            />
          </div>

          <div className="input-field">
            PAN
            <TextField
              size="small"
              value={storeData.pan || ""}
              name="pan"
              type="text"
              className="new-textfield"
              placeholder="PAN"
              onChange={onInputChange}
            />
          </div>

          <div className="input-field">
            GST
            <TextField
              size="small"
              value={storeData.gstn || ""}
              name="gstn"
              type="text"
              className="new-textfield"
              placeholder="GST"
              onChange={onInputChange}
            />
          </div>

          <div className="input-field">
            Contact Number
            <TextField
              size="small"
              value={storeData.contactNumber || ""}
              name="contactNumber"
              className="new-textfield"
              type="text"
              placeholder="Contact Number"
              onChange={onInputChange}
            />
          </div>

          <div className="input-field">
            Operating Entity Name
            <TextField
              size="small"
              value={storeData.franchiseBusinessName || ""}
              name="franchiseBusinessName"
              type="text"
              className="new-textfield"
              placeholder="Operating Entity Name"
              onChange={onInputChange}
            />
          </div>
          <div className="input-field">
            Store Hours
            <TextField
              size="small"
              value={storeData.storeHours || ""}
              name="storeHours"
              type="text"
              className="new-textfield"
              placeholder="Store Hours"
              onChange={onInputChange}
            />
          </div>
          <div className="input-field">
            Store Link
            <TextField
              size="small"
              value={storeData.storeLink || ""}
              name="storeLink"
              type="text"
              className="new-textfield"
              placeholder="Store Link"
              onChange={onInputChange}
            />
          </div>
          <div className="input-field">
            LFL
            <TextField
              size="small"
              value={storeData.tier || ""}
              name="tier"
              type="text"
              className="new-textfield"
              placeholder="LFL"
              onChange={onInputChange}
            />
          </div>
          <div className="input-field">
            <div className="mandatory-fields">
              Posist Key
              <span>*</span>
            </div>
            <TextField
              size="small"
              value={storeData.posistKey || ""}
              name="posistKey"
              type="text"
              className="new-textfield"
              placeholder="Posist Key"
              onChange={onInputChange}
            />
          </div>
          <div className="input-field">
            Cat
            <TextField
              size="small"
              value={storeData.cat || ""}
              name="cat"
              type="text"
              className="new-textfield"
              placeholder="Cat"
              onChange={onInputChange}
            />
          </div>
          <div className="input-field">
            FS Team Member
            <AutocompleteComponent
              classNamePrefix="custom_select"
              label="FS Team Member"
              className="sel-item"
              options={userList}
              value={
                ![undefined, null, ""].includes(storeData.firstLevelEscalation)
                  ? userList.find(
                      (i) => i.value === storeData.firstLevelEscalation
                    )
                  : ""
              }
              onChange={(e, val) => {
                onInputChange(
                  { name: "firstLevelEscalation", value: val?.value },
                  "select"
                );
              }}
              disableClear={true}
            />
          </div>
          <div className="input-field">
            <div className="mandatory-fields">
              Tier-City
              <span>*</span>
            </div>
            <TextField
              size="small"
              value={storeData.tierCity || ""}
              name="tierCity"
              type="text"
              className="new-textfield"
              placeholder="Tier-City"
              onChange={onInputChange}
            />
          </div>
          <div className="input-field">
            LSM Status
            <TextField
              size="small"
              value={storeData.lsmStatus || ""}
              name="lsmStatus"
              type="text"
              className="new-textfield"
              placeholder="LSM Status"
              onChange={onInputChange}
            />
          </div>
          <div className="input-field">
            Coffee Status
            <TextField
              size="small"
              value={storeData.coffeeStatus || ""}
              name="coffeeStatus"
              type="text"
              className="new-textfield"
              placeholder="Coffee Status"
              onChange={onInputChange}
            />
          </div>
          <div className="input-field">
            <div className="mandatory-fields">
              Store Closure Date
              {(storeData?.status === "Active" ||
                storeData?.status === "Inactive") && <span>*</span>}
            </div>
            <TextField
              size="small"
              value={
                storeData.storeClosureDate
                  ? new Date(storeData.storeClosureDate)
                      .toISOString()
                      .substring(0, 10)
                  : ""
              }
              name="storeClosureDate"
              type="date"
              className="new-textfield"
              placeholder="Store Closure Date"
              onChange={onInputChange}
            />
          </div>
          <div className="input-field">
            Remodel date
            <TextField
              size="small"
              // value={storeData.remodelDate || ""}
              value={
                storeData.remodelDate
                  ? new Date(storeData.remodelDate)
                      .toISOString()
                      .substring(0, 10)
                  : ""
              }
              name="remodelDate"
              type="date"
              className="new-textfield"
              placeholder="Remodel date"
              onChange={onInputChange}
            />
          </div>
          <div className="input-field">
            Outlier
            <TextField
              size="small"
              value={storeData.typeOfDecor || ""}
              name="typeOfDecor"
              type="text"
              className="new-textfield"
              placeholder="Outlier"
              onChange={onInputChange}
            />
          </div>
          <div className="input-field">
            DMB Type
            <AutocompleteComponent
              classNamePrefix="custom_select"
              label="DMB Type"
              className="sel-item"
              options={DMB_TYPE}
              value={
                ![undefined, null, ""].includes(storeData.dmbType)
                  ? DMB_TYPE.find((i) => i.value === storeData.dmbType)
                  : ""
              }
              onChange={(e, val) => {
                onInputChange({ name: "dmbType", value: val?.value }, "select");
              }}
              disableClear={true}
            />
          </div>
          <div className="input-field">
            Screens Format
            <AutocompleteComponent
              classNamePrefix="custom_select"
              label="Screens Format"
              className="sel-item"
              options={SCREEN_FORMAT}
              value={
                ![undefined, null, ""].includes(storeData.screensFormat)
                  ? SCREEN_FORMAT.find(
                      (i) => i.value === storeData.screensFormat
                    )
                  : ""
              }
              onChange={(e, val) => {
                onInputChange(
                  { name: "screensFormat", value: val?.value },
                  "select"
                );
              }}
              disableClear={true}
            />
          </div>
          <div className="input-field">
            Count Of Screens
            <TextField
              size="small"
              value={storeData.countOfScreens || ""}
              name="countOfScreens"
              type="number"
              className="new-textfield"
              placeholder="Count Of Screens"
              onChange={onInputChange}
            />
          </div>
          <div className="input-field">
            Store Covers
            <TextField
              size="small"
              value={storeData.storeCovers || ""}
              name="storeCovers"
              type="text"
              className="new-textfield"
              placeholder="Store Covers"
              onChange={onInputChange}
            />
          </div>
          <div className="save-button">
            <Button
              disabled={disableSaveButton(storeData)}
              size="small"
              variant="outlined"
              onClick={() =>
                createUpdateStore(
                  storeData,
                  setShowForm,
                  onSuccess,
                  url,
                  tokenKey
                )
              }
            >
              {storeData?.id ? "Update Store Details" : "Create new store"}
            </Button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};
