import React from "react";
import Chart from "react-apexcharts";
import "./index.scss";
import { IconButton } from "@mui/material";
import { exportToExcel } from "../../../common-utilities/utils";
import DownloadIcon from "@mui/icons-material/Download";

const CustomPiChart = ({
  title,
  data = [],
  xDataKey,
  bDataKey,
  enableDownload,
  name,
  convertKeys,
}) => {
  const width = window.innerWidth > 420 ? 500 : 300;

  const state = {
    options: {
      labels: data.map((d) => d[xDataKey]),
      legend: {
        position: "right",
      },
      dataLabels: {
        enabled: window.innerWidth > 450,
        formatter: function (val) {
          return val.toFixed(2) + "%";
        },
      },
      fill: {
        type: "gradient",
      },
    },
    series: data.map((d) => d[bDataKey]),
    labels: data.map((d) => d[xDataKey]),
  };

  return (
    <div className="pic-chart-container">
      <div className="title">
        {title}
        <IconButton
          style={{ display: enableDownload ? "" : "none" }}
          disabled={!data?.length}
          onClick={() => {
            if (convertKeys) {
              const convertedData =
                data?.map?.((item) => {
                  const convertedItem = {};
                  Object.keys(item).forEach((key) => {
                    const finalKey = convertKeys?.[key] || key;
                    const value = item?.[key];
                    convertedItem[finalKey] = value;
                  });
                  return convertedItem;
                }) || [];
              exportToExcel(convertedData, name || "");
              return;
            }
            exportToExcel(data, name || "");
          }}
        >
          <DownloadIcon />
        </IconButton>
      </div>
      {data.length > 0 ? (
        <div>
          <Chart
            options={state.options}
            labels={state.labels}
            series={state.series}
            type="donut"
            height={300}
            width={window.innerWidth > 450 ? undefined : width || "400px"}
          />
        </div>
      ) : (
        <div
          style={{
            width: `${width}px`,
            paddingTop: "40px",
          }}
        >
          No data to display
        </div>
      )}
    </div>
  );
};

export default CustomPiChart;
