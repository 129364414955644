import {
  clearProperty,
  setProperty,
} from '../../packages/common-utilities/utils/misc';
import { getAllDepartmentRoles } from './apis';

export const clearDepartment = (setFormData, setDepartmentRoleList) => {
  clearProperty('department', setFormData);
  clearProperty('departmentRole', setFormData);
  setDepartmentRoleList([]);
};

export const setEditData = (
  userData,
  setDepartmentRoleList,
  setFormData,
  setIsEmployee,
  setSelectedApps,
  setSelectedRoles,
  config,
  setConfig
) => {
  if (userData) {
    const {
      email,
      name,
      role,
      _id,
      limits,
      additionalRoles,
      department,
      departmentRole,
      isEmployee,
      vendor,
    } = userData;
    getAllDepartmentRoles({}, setDepartmentRoleList);
    const financeRoleMap = config.financeRoleList.reduce((uniqueMap, role) => {
      uniqueMap[role.value] = role.label;
      return uniqueMap;
    }, {});

    const allRoles = additionalRoles?.reduce?.(
      ({ additionalRoleLst, financeRoleLst }, roleId) => {
        if (financeRoleMap[roleId]) {
          financeRoleLst.push(roleId);
        } else {
          additionalRoleLst.push(roleId);
        }
        return { additionalRoleLst, financeRoleLst };
      },
      { additionalRoleLst: [], financeRoleLst: [] }
    ) || { additionalRoleLst: [], financeRoleLst: [] };

    const { additionalRoleLst, financeRoleLst } = allRoles;

    setFormData({
      email,
      name,
      role,
      _id,
      additionalRoles: additionalRoleLst,
      financeRoles: financeRoleLst,
      department,
      departmentRole: departmentRole ? parseInt(departmentRole) : null,
      vendor,
    });

    setIsEmployee(isEmployee);

    let showFinanceConfig = false;
    const rolesMap = {};
    const appList =
      limits?.map((item) => {
        const name = item?.app?.name || '';
        const value = item?.app?.id || '';
        if (parseInt(item?.app?.id) === parseInt(config.financeAppId)) {
          showFinanceConfig = true;
        }
        const roleId = item?.id;
        const roleName = item?.name;
        rolesMap[value] = { value: roleId, label: roleName };
        return { label: name, value: parseInt(value) };
      }) || [];
    setSelectedApps(appList);
    setSelectedRoles(rolesMap);
    setProperty('showFinanceConfig', showFinanceConfig, setConfig);
  }
};
