import React, { useState, useEffect } from "react";
import "./index.scss";
import LOGO from "../../resources/logo.png";
import EditIcon from "@mui/icons-material/Edit";
import { gettokenParamsForRedirectionToDifferentApp } from "../../utils";
import { useNavigate } from "react-router-dom";
import { SvgIcon } from "@mui/material";
import { LOGO_MAP } from "./config";

const AppCard = ({
  appDetails,
  clickFun,
  setImageFromProvidedImage,
  doNotAddTokenParams,
  downloadOnClick,
  notOpenApp,
  editInfo,
  noEditStyle,
}) => {
  const navigate = useNavigate();
  const [cardData, setCardData] = useState({
    name: "",
    url: "",
    image: "",
    role: "",
  });
  useEffect(() => {
    if (appDetails) {
      setCardData((prevVal) => {
        let additionalFilter = {};
        if (setImageFromProvidedImage) {
          additionalFilter.image = appDetails.image;
        }
        return {
          ...prevVal,
          name: appDetails?.name,
          url: appDetails?.link,
          role: appDetails?.role?.name,
          image: appDetails?.img,
          navigationLink: `${
            doNotAddTokenParams
              ? `${appDetails?.link}`
              : `${
                  appDetails?.link
                }${gettokenParamsForRedirectionToDifferentApp()}&slug=${
                  appDetails?.slug
                }`
          }`,
          ...additionalFilter,
        };
      });
    }
  }, []);

  return (
    <div
      className={`${downloadOnClick && "download-con"} details-con`}
      onClick={() => {
        if (downloadOnClick) {
          return;
        }
        if (cardData.url && !notOpenApp) {
          // if (cardData.url.includes("https")) {
          //   window.open(cardData.url);
          // } else {
          //   const includeSuffix = `https://${cardData.url}`;
          //   window.open(includeSuffix);
          // }
          // window.open(cardData.navigationLink);
          // window.location = cardData.navigationLink;
          navigate(`/${cardData.navigationLink}`);
        }
        if (notOpenApp) {
          editInfo(appDetails);
        }
        if (clickFun) {
          clickFun(true);
        }
      }}
    >
      <div
        className={
          notOpenApp && !noEditStyle ? "app-card edit-card" : "app-card"
        }
      >
        {downloadOnClick ? (
          <a
            className="con-div download-link"
            target="_blank"
            download={true}
            href={cardData?.url}
            style={{ textDecoration: "none" }}
          >
            <img
              src={cardData.image ? cardData.image : LOGO}
              alt="Card"
              className="card-app-image"
            />
            <p className="title">
              {cardData.name.split(" ").length > 7
                ? `${cardData.name.split(" ").slice(0, 7).join(" ")}...`
                : cardData.name}
            </p>
          </a>
        ) : (
          <div className="con-div">
            <div className="app-logo">
              <img
                src={cardData.image ? cardData.image : LOGO}
                alt="Card"
                className="card-app-image"
              />
            </div>
            <p className="title">
              {cardData.name.split(" ").length > 7
                ? `${cardData.name.split(" ").slice(0, 7).join(" ")}...`
                : cardData.name}
            </p>
          </div>
        )}
        <EditIcon className="edit-icon" fontSize="medium" />
      </div>
      <div className="app-info">
        {/* <p className="role">({cardData.role})</p> */}
      </div>
    </div>
  );
};

export default AppCard;
