import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";
import TableCell from "@mui/material/TableCell";
export const getHeaderConfig = () => {
  return [
    {
      label: "ID",
      id: "_id",
    },
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Last Modified On",
      id: "updatedAt",
      render: (data, onClick, index) => {
        return (
          <div id={index}>
            {moment(data?.updatedAt).format("dddd, MMMM Do YYYY, h:mm:ss a")}
          </div>
        );
      },
    },
    {
      label: "Allowed Apps",
      id: "allowedApps",
      render: (data, onClick) => {
        return (
          <RemoveRedEyeIcon
            onClick={() => onClick(data, "View Files")}
            style={{ cursor: "pointer" }}
          />
        );
      },
    },
    {
      label: "Action",
      id: "action",
      render: (data, onClick) => {
        return (
          <EditIcon
            onClick={() => onClick(data, "Edit")}
            style={{ cursor: "pointer" }}
          />
        );
      },
    },
  ];
};
export const ROLES = [
  { label: "User", value: "user" },
  { label: "Admin", value: "admin" },
  { label: "Content Creator", value: "content creator" },
  { label: "Franchise", value: "franchise" },
  {
    label:"Vendor", value:"vendor"
  }
];
